import * as SSOButton from 'coreplatform-web-component-artifact/dist/sso-button';
import { html, css } from 'coreplatform-web-component-artifact/dist/lit-element';

const SSOButtonClass = SSOButton.default;

const _ssoEnvironment = {
  dev: 'https://sso-becas.dev.universia.net/auth',
  stage: 'https://sso-becas.pre.universia.net/auth',
  prod: 'https://sso.becas-santander.com/auth',
};

const _appEnvironment = {
  dev: 'https://app-becas.dev.universia.net',
  stage: 'https://app-becas.pre.universia.net',
  prod: 'https://app.becas-santander.com',
};

function getSSOBaseUrl() {
  const environment =
    (window && window.sessionStorage && window.sessionStorage.getItem('environment')) || 'prod';
  return _ssoEnvironment[environment];
}

function getAppBaseUrl() {
  const environment =
    (window && window.sessionStorage && window.sessionStorage.getItem('environment')) || 'prod';
  return _appEnvironment[environment];
}

export const SUPPORTED_LOCALES = ['es', 'pt-BR', 'pt', 'ca', 'pl', 'de', 'en'];
export const DEFAULT_LOCALE = 'es';
export const DEFAULT_SU_SOCIAL_BUTTON_STYLE = css`
  :host(:not([hidden])) {
    --sso-button-background-color: #ec0000;
    --sso-button-text-color: #ffffff;
    --sso-button-font-family: 'Verdana';
    --sso-button-font-size: 14px;
    --sso-button-padding: 12px 35px;
    --sso-button-text--display: inline;
    --sso-button-border-radius: 24px;
    --sso-button-text-color--hover: #fff;
    --sso-button-border--hover: 1px solid #e42424;
    --sso-button-background-color--hover: #e42424;
    border-radius: 24px;
  }

  :host span {
    background-color: var(--santander-red);
  }

  :host .sso-su-sb__icon {
    margin-right: 8px;
  }

  :host .sso-su-sb__separator {
    font-size: 18px;
    line-height: 1.4;
  }

  :host .sso-su-sb__text {
    margin-left: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: white;
  }

  :host .sso-su-sb__container {
    display: flex;
    align-items: center;
    text-align: center;
  }
`;

export const DEFAULT_BUTTON_LITERALS = {
  es: {
    becasName: 'Becas&nbsp;Santander',
    continue_with: 'Continuar con',
  },
  en: {
    becasName: 'Santander&nbsp;Scholarships',
    continue_with: 'Continue with',
  },
  'pt-BR': {
    becasName: 'Bolsas&nbsp;Santander',
    continue_with: 'Continuar com',
  },
  pt: {
    becasName: 'Bolsas&nbsp;Santander',
    continue_with: 'Continuar com',
  },
  ca: {
    becasName: 'Becas&nbsp;Santander',
    continue_with: 'Continuar amb',
  },
  pl: {
    becasName: 'Santander&nbsp;Scholarships',
    continue_with: 'Kontynuuj z',
  },
  de: {
    becasName: 'Santander&nbsp;Scholarships',
    continue_with: 'Weiter mit',
  },
};

export default class SsoSuSocialButton extends SSOButtonClass {
  static get styles() {
    return [super.styles, DEFAULT_SU_SOCIAL_BUTTON_STYLE];
  }

  _localeLiterals() {
    // OR is not necessary here. See converter fn on properties
    let locale = (this._config && this._config.login.locale) || DEFAULT_LOCALE;
    if (SUPPORTED_LOCALES.indexOf(locale) < 0) {
      locale = DEFAULT_LOCALE;
    }

    this.literals = `${DEFAULT_BUTTON_LITERALS[locale].continue_with}`;
    this.stakeholderName = `${DEFAULT_BUTTON_LITERALS[locale].becasName}`;
  }

  getStakeholderName() {
    return document.createRange().createContextualFragment(`${this.stakeholderName}`);
  }

  static get properties() {
    return {
      config: {
        converter: value => {
          try {
            const config = JSON.parse(value);
            return {
              kc: {
                url: getSSOBaseUrl(),
                realm: 'becas',
                clientId: config.clientId,
              },
              init: {
                flow: 'implicit',
                onLoad: 'check-sso',
              },
              login: {
                redirectUri: `${getAppBaseUrl()}/es/sso-redirect?originRoute=${config.redirectUri}`,
                locale: config.locale || DEFAULT_LOCALE,
              },
            };
          } catch (ex) {
            return undefined;
          }
        },
      },
    };
  }

  render() {
    this._localeLiterals();

    return html`
      <div class="sso-su-sb__container">
        <svg
          class="sso-su-sb__icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g fill="none" fill-rule="evenodd">
            <g fill="#FFF">
              <g>
                <path
                  d="M15.607 8.868l-3.006-5.209c-.229-.397-.387-.822-.475-1.259L12 2.618c-.744 1.289-.744 2.877 0 4.166l2.405 4.167c.744 1.289.744 2.877 0 4.166l-.126.218c-.088-.437-.246-.862-.475-1.26l-2.202-3.814-1.406-2.435c-.229-.397-.387-.823-.475-1.26l-.126.218c-.741 1.285-.743 2.867-.007 4.154L12 15.118c.744 1.288.744 2.876 0 4.166l-.126.217c-.087-.436-.246-.862-.475-1.259l-3.006-5.208c-.403-.699-.588-1.485-.554-2.264-3.218.841-5.439 2.627-5.439 4.694 0 2.877 4.298 5.209 9.6 5.209s9.6-2.332 9.6-5.209c0-2.067-2.22-3.854-5.439-4.694-.029-.66-.213-1.312-.554-1.902z"
                  transform="translate(-514 -383) translate(514 383)"
                />
              </g>
            </g>
          </g>
        </svg>
        <span class="sso-su-sb__separator">|</span>
        <span class="sso-su-sb__text">${this.text} ${this.getStakeholderName()}</span>
      </div>
    `;
  }

  get config() {
    return this._config;
  }

  _updateConfig(oldValue, value) {
    // under config... just updates 'locale'
    if (value && value.login && value.login.locale) {
      this._config.login.locale = value.login.locale;
      this.requestUpdate('config', oldValue);
    }
  }

  set config(value) {
    const oldValue = this._config && { ...this._config };
    if (!oldValue && value) {
      // fires only the first time
      this._config = value;
      this.requestUpdate('config', oldValue);
    } else if (oldValue && value) {
      this._updateConfig(oldValue, value);
    }
  }

  constructor() {
    super();
  }
}

window.customElements.define('sso-su-social-button', SsoSuSocialButton);
